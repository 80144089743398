import React, { useState } from 'react'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import Expandable from './expandable.js'
import { notify } from '../lib/eventbus.js'

const footer = css`
  margin: 0 0 4px;
  line-height: 1.5;
  text-align: center;

  p {
    font-size: 1.2em;
    margin: 0.5em auto 2.5em;
  }

  [role="button"] {
    text-decoration: underline;
    cursor: pointer;
  }

`
const control = css`
    position: relative;
    height: 4em;
    background: white;

    hr {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 5em;
      border: 0;
      border-top-color: black;
      border-top-style: none;
      border-top-width: 0px;
      border-top: 1px solid #ccc;
      padding-top: 1em;
    }
    [role="button"] {
      font-size: 1.2em;
      text-decoration: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      padding: 0.25em 0.5em;
      color: #999;
      background: white;
      cursor: pointer;
    }
  }
`

export default function Footer() {
  const [showImpressum, setImpressum] = useState(false)
  const toggleImpressum = e => {
    if (e.type === 'keyup' && e.code !== 'Enter') return
    setImpressum(!showImpressum)
  }
  const openPrivacySettings = e => {
    if (e.type === 'keyup' && e.code !== 'Enter') return
    notify('footer', 'open-cm')
  }

  return (
    <footer css={footer}>
      <div css={control}>
        <hr />
        <div
          title="Impressum"
          aria-label="Impressum"
          role="button"
          tabIndex="0"
          onClick={toggleImpressum}
          onKeyUp={toggleImpressum}
        >$</div>
      </div>
      <Expandable expanded={showImpressum}>
        <p>
          Angaben gemäß § 5 TMG: <br />
          anzeit publishing, Jennifer An, Eisenacher Str. 48, 10823 Berlin <br />
          Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: <br />
          Jennifer An, Eisenacher Str. 48, 10823 Berlin <br />
          <span
            title="Privatsphären-Einstellungen"
            aria-label="Privatsphären-Einstellungen öffnen"
            role="button"
            tabIndex="0"
            className="privacy-button"
            onClick={openPrivacySettings}
            onKeyUp={openPrivacySettings}
          >
            Privatsphären-Einstellungen</span> <br />
          <Link title="Datenschutzerklärung" to="/datenschutz">Datenschutzerklärung</Link> <br />
        </p>
      </Expandable>
    </footer>
  )
}


