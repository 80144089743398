import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import Layout from '../components/layout.js'
import Header from '../components/logoHeader.js'

const main = css`
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`

export const query = graphql`
  query($slug: String!, $pdf: String!) {

    issuesJson(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      title
      flipbookUrl
      date
    }

    pdf: file(fields: {slug: {eq: $pdf}}, ext: {eq: ".pdf"}) {
      publicURL
    }

    og: file(
      ext: {eq: ".jpg"}
      name: {eq: $slug}
      relativeDirectory: {eq: "pdf-thumbnails"}
    ) {
      childImageSharp {
        fixed(height: 1000, webpQuality: 90, toFormat: WEBP) {
          src
        }
      }
    }

  }
`

export default function Issue({ data }) {
  const { issuesJson: {title, fields: {slug}} } = data
  const url = data.pdf.publicURL
  const description = `anzeit publishing pdf issue: ${title}`
  const ogImage = data.og.childImageSharp.fixed.src

  return (
    <Layout>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <link rel="canonical" href={`https://money-times.de/${slug}`} />
        <meta name="description" content={description} />
        {/* <meta name="image" content={image} /> */}
        {/* OpenGraph tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://money-times.de/${slug}`} />
        <meta property="og:image" content={`https://money-times.de/${ogImage}`} />
      </Helmet>
      <Header />
      <main css={main}>
        <iframe
          title={ title }
          src={ url }
          style={{ border: 0 }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen={true}
          allowtransparency="true" />
      </main>
    </Layout>
  )
}


