import * as React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import './layout.css'
import Footer from '../components/footer.js'
import ConsentManagement from '../components/consentManagement.js'

/*
 * Basic layout for all pages:
 * - head: meta & font-links
 * - footer
 * - consent management
 */

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query Fonts {

          allFile(
            filter: {ext: {regex: "/(.woff2)/"}, relativeDirectory: {eq: "fonts"}}
            sort: {fields: extension, order: DESC}
          ) {
            edges {
              node {
                publicURL
                extension
                id
              }
            }
          }

        }
      `}
      render={ ({ allFile: { edges } }) => (
        <>
          <Helmet htmlAttributes={{ lang: 'de', }}>
            { edges.map(({node:{ id, publicURL, extension }}) => (
              <link
                key={id}
                rel="preload"
                href={publicURL}
                as="font"
                type={`font/${extension}`}
                crossorigin="anonymous" />
            ))}
            <meta name="publisher" content="anzeit publishing" />
          </Helmet>

          { children }

          <Footer />

          <ConsentManagement />
        </>
      )}
    />
  )
}

