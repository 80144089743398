/**
 *
 * event-bus
 * - works as a central event-bus / event-dispatcher / message-broker
 * - allows modules to subscribe to messages (topics) and register a callback
 * - allows modules to publish/notify/broadcast any messages
 * - notifies subscribers by executing the registered callback
 *
 * implementation notes:
 * - realized as a "singleton" using the 'revealing-module'-like pattern
 * - uses the topic-based publish-subscribe pattern,
 *   - with static/fixed subscription
 *   - with topic clustering
 * - uses only one message object, b/c javascript is single threaded and
 *   runs to completion
 *
 */

const MESSAGE = {
  channel: 'channel',
  message: 'message',
  payload: {}
}

let subscribers = []

const subscriptionExists = (channel, message, callback) => {
  return subscribers.some(s => {
    return s.channel===channel && s.message===message && s.callback===callback
  })
}

const addSubscriber = (channel, message, callback) => {
  if (subscriptionExists(channel, message, callback)) return false
  subscribers.push({channel, message, callback})
  return true
}

// use this, if the need for dynamic subscriptions arrises
const removeSubscriber = (channel, message, callback) => {
  let removalSuccess = false
  subscribers = subscribers.filter(s => {
    if(s.channel===channel && s.message===message && s.callback===callback) {
      removalSuccess = true
      return false
    }
    return true
  })
  return removalSuccess
}

// notify / publish / broadcast message
const publish = (channel, message, payload) => {
  MESSAGE.channel = channel || 'CHANNEL-UNSPECIFIED'
  MESSAGE.message = message || 'MESSAGE-UNSPECIFIED'
  MESSAGE.payload = payload || {}

  // console.log('EventBus/publish', MESSAGE)

  subscribers.forEach(s => {
    if (channel!==s.channel) return
    if (message!==s.message) return
    s.callback(MESSAGE)
  })
}

export const on = addSubscriber

export const off = removeSubscriber

export const notify = publish

