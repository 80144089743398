import * as React from 'react'
import {Helmet} from 'react-helmet'
import { graphql, Link } from 'gatsby'
// import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Img from 'gatsby-image'
import { css } from '@emotion/react'
import Layout from '../components/layout.js'
import Header from '../components/minLogoHeader.js'

const mainCss = css`
  padding: 23em 0 0 0;

  ol {
    list-style: none;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 1400px;
    margin: 0 auto;
    padding: 0;
  }

  @media screen and (max-width: 800px) {
    ol { // expanded mobile header
      margin-top: 12em;
    }
  }

  li {
    margin: 1.5em;
    text-align: center;

  }

  // pre-publish new issue (hide newest issue thumb)
  // li:first-of-type {
  //   display: none;
  // }

  // li:nth-of-type(2) { // pre-publish new issue (hide newest issue thumb)
  li:first-of-type { // regular publish
    /* initial-anim */
    /* @keyframes duration | timing-function | delay |
    iteration-count | direction | fill-mode | play-state | name */
    animation: 3s linear 1s 3 normal none running;
    animation-name: shake;/*
    animation-duration: 3.5s;
    transform-origin:50% 50%;
    animation-iteration-count: 3;*/
  }
  // img {
  //   display: block;
  //   max-height: 400px;
  // }
  img:hover {
    animation: 2.5s linear 0s infinite normal none running;
    animation-name: shake2;
  }
  .title {
    margin-top: 1em;
  }
  .name {
    font-size: 1.4em;
    font-weight: 700;
  }
  .date {
    font-size: 1.1em;
    font-weight: 700;
    margin-left: 0.3em;
  }
  .publisher {
    font-size: 1.1em;
    font-weight: 300;
    margin-top: 0.3em;
  }
  .pdf {
    display: none;
  }

  .lametta-anim {
    /* @keyframes duration | timing-function | delay |
    iteration-count | direction | fill-mode | play-state | name */
    animation: 3s linear 1s infinite normal none running;
    animation-name: shake;/*
    animation-duration: 3.5s;
    transform-origin:50% 50%;
    animation-iteration-count: 3;*/
  }

  @keyframes shake {
    0% { transform:  scale(1, 1) rotate(0deg); }
    2% { transform:  scale(1, 1) rotate(-0.5deg); }
    4% { transform:  scale(1, 1) rotate(0.5deg); }
    6% { transform:  scale(1.005, 1.005) rotate(-1deg); }
    8% { transform:  scale(1.01, 1.01) rotate(1deg); }
    10% { transform: scale(1.02, 1.02) rotate(-2deg); }
    12% { transform: scale(1.02, 1.02) rotate(2deg); }
    14% { transform: scale(1.01, 1.01) rotate(-2deg); }
    16% { transform: scale(1.005, 1.005) rotate(1deg); }
    18% { transform: scale(1, 1) rotate(-0.5deg); }
    20% { transform: scale(1, 1) rotate(0deg); }
  }
  @keyframes shake2 {
    0% { transform:   rotate(-0.5deg); }
    10% { transform:  rotate( 0.5deg); }
    20% { transform:  rotate(-0.5deg); }
    30% { transform:  rotate( 0.5deg); }
    40% { transform:  rotate(-0.5deg); }
    50% { transform:  rotate( 0.5deg); }
    60% { transform:  rotate(-0.5deg); }
    70% { transform:  rotate( 0.5deg); }
    80% { transform:  rotate(-0.5deg); }
    90% { transform:  rotate( 0.5deg); }
    100% { transform: rotate(-0.5deg); }
  }
  [ssg] {
    display: none;
  }
`

const image = css`
  display: block;
  width: 300px;
  height: 400px;
`

export const query = graphql`
  {

    allIssuesJson(sort: {order: DESC, fields: date}) {
      edges {
        node {
          title
          date
          flipbookUrl
          fields {
            slug
            name
            date
          }
        }
      }
    }

    allFile(filter: {relativeDirectory: {eq: "pdf-thumbnails"}}) {
      edges {
        node {
          name
          childImageSharp {
            # gatsbyImageData(
            #   height: 400
            #   quality: 90
            #   jpgOptions: {quality: 90, progressive: true}
            #   placeholder: NONE
            #   webpOptions: {quality: 90}
            # )
            fluid(quality: 90, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

  }
`

export default function Index({ data: { allIssuesJson, allFile } }) {
  const issues = allIssuesJson.edges.map(d => d.node.fields)
  const img = allFile.edges.map(({node}) => node)
  const description = 'tbd'

  // <StaticImage alt="alt-description" src="../assets/pdf-thumbnails/lametta-2020.jpg" />
  return (
    <Layout>
      <Helmet>
        {/* General tags */}
        <title>Money/Times</title>
        <link rel="canonical" href="https://money-times.de/" />
        <meta name="description" content={description} />
        {/* <meta name="image" content={image} /> */}
        {/* OpenGraph tags */}
        <meta property="og:title" content="Money/Times" />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://money-times.de/" />
        {/* <meta property="og:image" content="" /> */}
      </Helmet>
      <Header />
      <main css={mainCss}>
       <ol>
         {issues.map(({ slug, name, date }) => (
           <li key={slug}>
             <Link to={`/${slug}`}>
               {/* <GatsbyImage image={getImage(img.find(d => d.name === slug))} */}
               {/*   alt={`${name} ${date} Titelbild`} /> */}
               <Img css={image}
                 fluid={img.find(d => d.name === slug).childImageSharp.fluid}
                 alt={`${name} ${date} Titelbild`} />
             </Link>
             <div className="title">
               <span className="name">{name} </span>
               <span className="date">{date}</span>
             </div>
             <div className="publisher">anzeit publishing</div>
           </li>
         ))}
       </ol>
      </main>
    </Layout>
  )
}

