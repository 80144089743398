import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
// import throttle from 'lodash/throttle'
import Layout from '../components/layout.js'
import Header from '../components/logoHeader.js'
import { on, off, notify } from '../lib/eventbus.js'

const main = css`
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
const noConsent = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  text-align: center;

  p {
    font-size: 1.6em;
  }
  .privacy-button {
    font-size: 1.6em;
    text-decoration: underline;
    cursor: pointer;
  }
`

export const query = graphql`
  query($slug: String!) {

    issuesJson(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      title
      flipbookUrl
      date
    }

    og: file(
      ext: {eq: ".jpg"}
      name: {eq: $slug}
      relativeDirectory: {eq: "pdf-thumbnails"}
    ) {
      childImageSharp {
        fixed(height: 1000, webpQuality: 90, toFormat: WEBP) {
          src
        }
      }
    }

  }
`
const getFlipbookProvider = flipbookUrl => {
  if (flipbookUrl.match(/www.yumpu.com/)) return 'yumpu'
  if (flipbookUrl.match(/online.anyflip.com/)) return 'anyflip'
  return 'unknown'
}

export default function Issue({ data }) {
  const { issuesJson: {title, flipbookUrl, fields: {slug}} } = data
  const description = `anzeit publishing flipbook issue: ${title}`
  const ogImage = data.og.childImageSharp.fixed.src

  const openPrivacySettings = e => {
    if (e.type === 'keyup' && e.code !== 'Enter') return
    notify('issue', 'open-cm', { flipbookProvider })
  }

  const [ consent, setConsent ] = useState(false)

  const flipbookProvider = getFlipbookProvider(flipbookUrl)

  useEffect(() => {
    const onConsentUpdate = ({payload: {services}}) => {
      const s = services.find(s => s.id === flipbookProvider)
      setConsent(s.consent)
    }
    on('cm', 'set', onConsentUpdate)
    on('cm', 'available', onConsentUpdate)

    // const resize = () => {
    //     let visibleHeight = window.innerHeight + 'px'
    //     document.body.style.height = visibleHeight
    //     document.querySelector('#gatsby-focus-wrapper').style.height = visibleHeight
    // }
    // const onResize = throttle(resize, 20, { leading: false, trailing: true })
    // window.addEventListener('resize', onResize)
    // resize()

    return () => {
      off('cm', 'set', onConsentUpdate)
      off('cm', 'available', onConsentUpdate)
      // window.removeEventListener('resize', onResize)
    }
  })

  return (
    <Layout>
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <link rel="canonical" href={`https://money-times.de/${slug}`} />
        <meta name="description" content={description} />
        {/* <meta name="image" content={image} /> */}
        {/* OpenGraph tags */}
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://money-times.de/${slug}`} />
        <meta property="og:image" content={`https://money-times.de/${ogImage}`} />
      </Helmet>
      <Header />
      <main css={main}>
        { consent && (
        <iframe
          title={ title }
          src={ flipbookUrl }
          style={{ border: 0 }}
          scrolling="no"
          frameBorder="0"
          allowFullScreen={true}
          allowtransparency="true" />
        )}
        { !consent && (
        <div css={noConsent}>
          <h1>Nutzerzustimmung ausstehend</h1>
          <p>Für die Darstellung interaktiver Magazininhalte nutzen wir externe Services, wie {flipbookProvider}. Hierzu benötigen wir Ihre Zustimmung.</p>
          <span
            title="Privatsphären-Einstellungen"
            aria-label="Privatsphären-Einstellungen öffnen"
            role="button"
            tabIndex="0"
            className="privacy-button"
            onClick={openPrivacySettings}
            onKeyUp={openPrivacySettings}
          >Privatsphären-Einstellungen anpassen</span>
          <noscript>
            <p>Für die Verwaltung Ihrer Zustimmungen benötigen wir JavaScript.</p>
          </noscript>
        </div>
        )}
      </main>
    </Layout>
  )
}


