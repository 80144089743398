import * as React from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import { css } from '@emotion/react'
import Layout from '../components/layout.js'
import Header from '../components/logoHeader.js'

const main = css`
  position: relative;

  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
const info = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  text-align: center;

  p {
    font-size: 1.6em;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export default function NotFoundPage() {

  return (
    <Layout>
      <Helmet>
        <title>Seite nicht verfügbar 404</title>
        <link rel="canonical" href={`https://money-times.de/404`} />
        <meta name="description" content="page not found 404" />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Header />
      <main css={main}>
        <div css={info}>
          <h1>Seite nicht verfügbar</h1>
          <p><Link to="/">Zurück zur Homepage</Link></p>
        </div>
      </main>
    </Layout>
  )
}
