import React, { useState, useEffect } from 'react'
import { css } from '@emotion/react'
import { Link, StaticQuery, graphql } from 'gatsby'
import Expandable from './expandable.js'
import SwitchButton from './switchButton.js'
import { on, off, notify } from '../lib/eventbus.js'
import { deferToAfterReflow as defer } from '../lib/dom.js'

const cm = css`
  overflow: auto;
  position: fixed;
  top: 0; /* ie11 fix */
  width: 100%; /* ie11 fails sizing corectly, but aceptable*/
  height: 100%; /* ie11 fails sizing correctly, but aceptable */
  z-index: 10;

  .bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(8px);
  }
  .popup {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 8em);
    height: auto;
    max-width: 640px;
    border: 1px solid;
    padding: 3em;
    background: white;
    box-shadow: 0 0 24px rgba(0, 0, 0, 0.8);
  }
  p {
    font-size: 1.6em;
  }
  .impressum-button + a, a + a {
    margin-left: 0.5em;
  }
  .impressum-button {
    cursor: pointer;
    text-decoration: underline;
  }
  ol {
    list-style: none;
    padding: 0;
    margin: 1.6em 0;
  }
  li {
    display: block;
    border: 1px solid #CCC;
    border-radius: 4px;
    padding: 1em;
    margin-bottom: 1em;
    & > div {
      margin-bottom: 0.5em;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
  }
  .name {
    font-size: 1.6em;
    font-weight: 700;
  }
  .type {
    margin-top: 0.2em;
    font-size: 1.4em;
    display: inline-block;
    font-weight: 400;
  }
  .essential {
    opacity: 0.5;
  }
  .actions {
    margin-top: 3.2em;
    text-align: center;
  }
  button {
    appearance: none;
    cursor: pointer;
    color: #ffffff;
    font-size: 1.6rem;
    margin: 0;
    text-decoration: none;
    line-height: 1;
    text-align: center;
    display: inline-block;
    border: none;
    border-radius: 4px;
    padding: 1rem 2rem;
    background: #0069ed;
    transition: background 250ms ease-in-out, transform 150ms ease;
  }
  button:hover {
    background: #0053ba;
  }
  button:focus {
    outline: 1px solid white;
    outline-offset: -4px;
  }
  .expand-button {
    font-size: 1.6em;
    position: relative;
    background-color: white;
    /* padding: 0.25em 1.5em 0.25em 0.5em; */
    padding: 0 0.75em;
    cursor: pointer;
    border: 1px solid #CCC;
    border-radius: 4px;
  }
  .expand-button:focus {
    outline: none;
    box-shadow: 0 0 0 2px #0069ed;
  }

  /* drop-down arrow */
  .expand-button::after {
    position: absolute;
    content: "";
    top: calc(50% - 0.125em);
    right: 0.45em;
    width: 0;
    height: 0;
    border: 0.3em solid transparent;
    border-color: black transparent transparent transparent;
  }

  /* drop-down arrow open */
  .expand-button.open::after {
    border-color: transparent transparent black transparent;
    top: calc(50% - 0.5em);
  }
`

const clearCmData = localStorage => {
  Object.keys(localStorage).forEach(key => {
    if (key.match(/^cm-/)) localStorage.removeItem(key)
  })
}

const isVersionInvalid = (localStorage, version) => {
  return localStorage.getItem('cm-version') !== version
}

const isStale = (localStorage, maxAgeDays) => {
  const consentDate = localStorage.getItem('cm-date') // cm-date example: 2020-01-24
  if (typeof consentDate !== 'string') return true
  if (consentDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
    const today = new Date()
    const msToDays = 1 / 1000 / 60 / 60 / 24
    const daysPassed = (today - new Date(consentDate)) * msToDays
    if (daysPassed > maxAgeDays) return true
  }
  return false
}

const isServicesInvalid = (localStorage, services) => {
  // settings unavailable or invalid
  let invalidServiceFound = services.some(s => {
    let consent = localStorage.getItem(`cm-${s.id}`) // string
    // consent value missing
    if (typeof consent !== 'string') return true
    // consent value invalid, user manipulation
    if (consent === 'false' && s.type.id === 'essential') return true
    // invalid consent value, user manipulation
    if (consent !== 'true' && consent !== 'false') return true
    return false
  })
  return invalidServiceFound
}

const writeCmData = (localStorage, services) => {
  // settings that were not adjusted, need to be saved
  let isChanged = false
  services.forEach(s => {
    if (localStorage.getItem(`cm-${s.id}`) !== String(s.consent)) {
      localStorage.setItem(`cm-${s.id}`, s.consent)
      isChanged = true
    }
  })
  if (!localStorage.getItem('cm-version'))
    localStorage.setItem('cm-version', version)
  if (isChanged)
    localStorage.setItem('cm-date', new Date().toISOString().slice(0, 10))
}


const version = '1' // string, because potentially semver
const maxAgeDays = 365

const Render = ({ allDataServicesJson: { edges } }) => {
  // init states
  const [ services, updateServices ] = useState(edges.map(({node}) => ({
    ...node,
    consent: node.type.id === 'essential',
    disabled: node.type.id === 'essential',
    expanded: false
  })))

  const [isImpressum, setImpressum] = useState(false)

  const [ isVisible, setVisible ] = useState(false)

  const [ isSynced, setIsSynced ] = useState(false) // once

  // callbacks

  const toggleImpressum = e => {
    if (e.type === 'keyup' && e.code !== 'Enter') return
    setImpressum(!isImpressum)
  }

  const setServiceConsent = (service, consent) => {
    service.consent = !service.consent
    updateServices(services.map(s => s))
  }

  const toggleServiceDetails = service => {
    service.expanded = !service.expanded
    updateServices(services.map(s => s))
  }

  const onAck = () => {
    writeCmData(window.localStorage, services)
    setVisible(false)
    // notify settings
    notify('cm', 'set', { services })
  }

  // init event listeners

  useEffect(() => {
    const onOpenSettings = () => setVisible(true)
    on('footer', 'open-cm', onOpenSettings)
    on('issue', 'open-cm', onOpenSettings)

    return () => {
      off('footer', 'open-cm', onOpenSettings)
      off('issue', 'open-cm', onOpenSettings)
    }
  })

  // init once: sync from localStorage

  useEffect(() => {
    if (!isSynced) {
      setIsSynced(true)
      // clear localStorage & open dialog, when:
      // - version unavailable or mismatch
      // - localStorage data stale
      // - localStorage data invalid
      if (isVersionInvalid(window.localStorage, version) ||
          isStale(window.localStorage, maxAgeDays) ||
          isServicesInvalid(window.localStorage, services)) {
        clearCmData(window.localStorage)
        setVisible(true) // open dialog
        return
      }
      // settings eligible, do not open dialog
      // sync settings from localStorage & notify
      updateServices(services.map(s => {
        let consent = window.localStorage.getItem(`cm-${s.id}`) // string
        s.consent = consent === 'true' // must be boolean
        return s
      }))
      // notify (deferred, because of react's virtual dom crap)
      defer(1, () => notify('cm', 'available', { services }))
    }
  }, [isSynced, services])

  useEffect(() => {
    if (isVisible) {
      // setProperty/removeProperty ie11 style
      document.querySelector('body').style.setProperty('position', 'overflow')
      document.querySelector('body').style.setProperty('height', '100vh')
      document.querySelector('body').style.setProperty('overflow', 'hidden')
      return
    }
    document.querySelector('body').style.removeProperty('position')
    document.querySelector('body').style.removeProperty('height')
    document.querySelector('body').style.removeProperty('overflow')
  }, [isVisible])

  // render

  if (!isVisible) return <></>

  return (
    <div css={cm}>
      <div className="bg" />
      <div className="popup">

        <h1>Privatsphären-Einstellungen</h1>
        <p>Wir verwenden Cookies, um den Betrieb der Webseite sicherzustellen und Ihnen eine optimale Erfahrung zu bieten. Für die Darstellung interaktiver Magazin-Inhalte nutzen wir externe Services. Dadurch entstehen Datenverbindungen zu den jeweiligen Service-Betreibern mit jeweils eigenen Datenschutzrichtlinien. Durch klicken auf „Einverstanden“ bestätigen Sie, dass Sie über 16 Jahre alt sind und wir Cookies setzen und Datenverbindungen zu den beschriebenen Services aufnehmen dürfen.</p>

        <p>
          <span
            className="impressum-button"
            role="button"
            tabIndex="0"
            title="Impressum einblenden"
            aria-label="Impressum einblenden"
            onKeyUp={toggleImpressum}
            onClick={toggleImpressum}
          >Impressum</span>
          <Link
            title="Datenschutzerklärung"
            to="/datenschutz">Datenschutzerklärung</Link>
            { /* <a title="Allgemeine Geschäftsbedingungen" href="/agb">Allgemeine Geschäftsbedingungen</a> */}
        </p>

        <Expandable expanded={isImpressum}>
          <p>
            Angaben gemäß § 5 TMG:
            anzeit publishing, Jennifer An, Eisenacher Str. 48, 10823 Berlin
            Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:
            Jennifer An, Eisenacher Str. 48, 10823 Berlin
          </p>
        </Expandable>

        <h1>Services</h1>
        <ol>
          { services.map(s => (
          <li key={s.id}>
            <div className="title">
                <div className="name">{s.name}</div>
                <div className={s.type.id === 'essential' ? 'type essential' : 'type'}>{s.type.label}</div>
                <SwitchButton
                  checked={s.consent}
                  disabled={s.disabled}
                  onChange={e => setServiceConsent(s, e.target.checked)}/>

                <div
                  className={!s.expanded ? 'expand-button open' : 'expand-button '}
                  role="button"
                  tabIndex="0"
                  title={`${s.name} Service Details einblenden`}
                  aria-label={`${s.name} Service Details einblenden`}
                  onKeyUp={e => {if (e.code === 'Enter') toggleServiceDetails(s)}}
                  onClick={e => toggleServiceDetails(s)} />
            </div>
            <Expandable aria-expanded={s.expanded} expanded={s.expanded}>
              <p>{s.description}</p>
              <p>{s.address}</p>
              <p>
                { s.links.map(link => (
                <a
                  tabIndex={s.expanded ? 0 : -1}
                  key={s.id + link.label}
                  title={link.label}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  >{link.label}</a>
                ))}
              </p>
            </Expandable>
          </li>
          ))}
        </ol>

        <div className="actions">
          <button onClick={onAck}>Einverstanden</button>
        </div>
      </div>
    </div>
  )
}
const ConsentManagement = () => {
  // const onAck = () => console.log('onAck')
  // const toggleImpressum = () => console.log('toggleImpressum')
  // const toggleDescription = () => console.log('toggleDescription')
  // const services = allDataServicesJson.edges.map(({node}) => node)

  return (
    <StaticQuery
      query={graphql`
        query Services {
          allDataServicesJson {
            edges {
              node {
                links {
                  label
                  url
                }
                id
                name
                description
                address
                type {
                  id
                  label
                }
              }
            }
          }
        }
      `}
      render={Render}
    />
  )
}

export default ConsentManagement
