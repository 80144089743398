import React, { useRef, useEffect } from 'react';
import { css } from '@emotion/react'

const expandable = css`
  overflow: hidden;
  transition: height .8s;
`

const Expandable = ({ children, expanded }) => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.setAttribute('aria-expanded', expanded)
  })
  const style = !expanded ? { height: 0 } :
    (ref.current ? { height: + ref.current.scrollHeight + 'px' } : {} )

  const onEnd = () => { /* TODO: remove height inline style*/  }

  return (
    <div ref={ref} style={style} css={expandable} onTransitionEnd={onEnd}>
      {children}
    </div>
  )
}

export default Expandable
