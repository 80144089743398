import React from 'react'
import { css} from '@emotion/react'

const switchButton = css`
  margin-top: 0.1em;
  font-size: 2em;
  position: relative;
  display: inline-block;
  width: 2em;
  height: 1em;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #777;
    transition: transform .4s, background-color .4s;
    border-radius: 34px;
    pointer-events: none;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: calc(1em - 3px);
    width: calc(1em - 3px);
    left: 2px;
    bottom: 2px;
    background-color: white;
    transition: transform .4s;
    border-radius: 50%;
    pointer-events: none;
  }

  input:checked + .slider {
    background-color: #0069ed;
  }

  input:focus + .slider {
    outline: none;
    box-shadow: 0 0 0 2px #0069ed;
  }

  input:checked + .slider:before {
    transform: translateX(calc(1em - 1px));
  }

  // .slider[disabled="true"] {
  .slider.disabled {
    cursor: default;
  }
  // input:checked + .slider[disabled="true"] {
  input:checked + .slider.disabled {
    background-color: #ccc;
  }
`

const SwitchButton = ({value, checked, disabled, onChange}) => {
  return (
    <label css={switchButton}>

      <input
        tabIndex="0"
        type="checkbox"
        value={value}
        checked={checked}
        disabled={disabled}
        onChange={onChange} />

      <span
        className={disabled ? 'slider disabled' : 'slider'}
        disabled={disabled} />

    </label>
  )
}

export default SwitchButton
