import React from 'react'
import { css } from '@emotion/react'
import { Link } from 'gatsby'
import logo from '../assets/svg/money-times-logo.svg'

const headerCss = css`
  text-align: center; /* fix for ie11, a below has unknown/implicit width */

  a {
    display: block;
    margin: 0 auto;
    width: max-content;
  }
  img {
    height: 2.8em;
    padding: 1em;
    z-index: 1;
    cursor: pointer;
  }
`

const Header = () => (
  <header css={headerCss}>
    <Link to="/"><img alt="Money/Times logo" src={logo} /></Link>
  </header>
)

export default Header
