import React, { useRef, useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { css  } from '@emotion/react'
import throttle from 'lodash/throttle'
import mtLogo from '../assets/svg/money-times-logo.svg'
import azSignet from '../assets/svg/anzeit-signet.svg'
import { FSM } from '../lib/fsm.js'

const headerCss = css`
  width: 100%;
  z-index: 1;
  text-align: center;

  .bg {
    position: absolute;
    background: white;
    padding: 6em 0 0;
    width: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    flex-flow: row;
    align-items: center;
    overflow: hidden;
  }

  .logo {
    width: auto;
    height: 9em;
    padding: 1em;
    transition: height 0.1s;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .az {
    position: absolute;
    right: 1em;
    margin-bottom: 0;
  }

  .mt {
    width: 43.4782em;
  }

  @media screen and (max-width: 800px) {
    .bg {
      flex-flow: column;
    }
    .az {
      position: static;
      right: auto;
      margin-bottom: 1em;
    }
  }

  @media screen and (max-width: 500px) {
    .mt {
      max-height: 18vw !important;
    }
  }
`

const { init, dispatch, tran } = FSM.create()

const Header = () => {
  const bgElm = useRef(null)
  const mtElm = useRef(null)
  const azElm = useRef(null)
  const [ fixedTs, setFixedThreshold ] = useState(0)
  const [ shadowTs, setShadowThreshold ] = useState(0)
  const [ bgStyle, setBgStyle  ] = useState({})
  const [ mtStyle, setMtStyle  ] = useState({})
  const [ azStyle, setAzStyle  ] = useState({})

  useEffect(() => {
    setFixedThreshold(mtElm.current.offsetTop)
    setShadowThreshold(mtElm.current.offsetTop + mtElm.current.offsetHeight)

    const resize = () => {
      setFixedThreshold(mtElm.current.offsetTop)
      setShadowThreshold(mtElm.current.offsetTop + mtElm.current.offsetHeight)
    }
    const onResize = throttle(resize, 20, { leading: false, trailing: true })
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [ fixedTs, shadowTs ])

  useEffect(() => {
    const boxShadow = '0 2px 4px 0 rgba(0, 0, 0, 0.2)'
    const full = msg => {
      switch (msg) {
        case ('scroll'):
          if (window.scrollY >= fixedTs) tran(fixed)
          break
        case ('enter'):
          // console.log('enter 0 full')
          if (window.scrollY >= fixedTs) tran(fixed)
          setBgStyle({})
          setMtStyle({})
          setAzStyle({})
          break
        default: break
      }
    }
    const fixed = msg => {
      switch (msg) {
        case ('scroll'):
          if (window.scrollY < fixedTs) return tran(full)
          if (window.scrollY >= shadowTs) return tran(shadowScaling)
          break
        case ('enter'):
          // console.log('enter 1 fixed')
          if (window.scrollY < fixedTs) return tran(full)
          if (window.scrollY >= shadowTs) return tran(shadowScaling)
          setBgStyle({ position: 'fixed', top: `${ -fixedTs }px` })
          setMtStyle({})
          setAzStyle({})
          break
        default: break
      }
    }

    // scaling
    const shadowScaling = msg => {
      switch (msg) {
        case ('scroll'):
          if (window.scrollY < shadowTs) return tran(fixed)
          if (window.scrollY - shadowTs >= 100) return tran(minimized)
          let scale = (window.scrollY - shadowTs) / 100
          let height = `${9 - (5 * scale)}em` // min: 4em, max: 9em
          let width = `${ height * 4.7619 }em` // 9em * 4,761904761904762 (300/63)
          setMtStyle({ height, width })
          if (window.innerWidth >= 800) setAzStyle({ height })
          else setAzStyle({})
          break
        case ('enter'):
          // console.log('enter 2 shadowScaling')
          if (window.scrollY < shadowTs) return tran(fixed)
          if (window.scrollY - shadowTs >= 100) return tran(minimized)
          setBgStyle({ position: 'fixed', top: `${ - fixedTs }px`, boxShadow })
          scale = (window.scrollY - shadowTs) / 100
          height = `${9 - (5 * scale)}em` // min: 4em, max: 9em
          width = `${ height * 4.7619 }em` // 9em * 4,761904761904762 (300/63)
          setMtStyle({ height, width })
          if (window.innerWidth >= 800) setAzStyle({ height })
          else setAzStyle({})

          break
        default: break
      }
    }
    const minimized = msg => {
      switch (msg) {
        case ('scroll'):
          if (window.scrollY - shadowTs < 100) return tran(shadowScaling)
          break
        case ('enter'):
          // console.log('enter 3 minimized')
          if (window.scrollY - shadowTs < 100) return tran(shadowScaling)
          setBgStyle({ position: 'fixed', top: `${ - fixedTs }px`, boxShadow })
          setMtStyle({ height: '4em' }) // 9em - 5em
          if (window.innerWidth >= 800) setAzStyle({ height: '4em' })
          else setAzStyle({ height: '9em' })
          break
        default: break
      }
    }

    // init statemachine, start with any state, fsm will figure it out
    init(minimized)

    // dom-events
    const onScroll = throttle(() => dispatch('scroll'), 20,
      { leading: false, trailing: true })
    window.addEventListener('scroll', onScroll)
    window.addEventListener('wheel', onScroll)
    return () => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('wheel', onScroll)
    }
  }, [ fixedTs, shadowTs, bgElm, azElm, mtElm ])

  return (
    <header css={headerCss}>
      <div ref={ bgElm } style={ bgStyle } className="bg">

        <a ref={ azElm } style={ azStyle } className="az logo"
          href="https://an-zeit.de" target="_blank">
          <img src={ azSignet } alt="anzeit signet" width="150" height="150"/>
        </a>

        <Link ref={ mtElm } style={ mtStyle } className="mt logo" to="/" >
          <img src={ mtLogo } alt="Money/Times logo" width="300" height="63"/>
        </Link>

      </div>
    </header>
  )
}

export default Header
