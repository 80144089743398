import * as React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import { css } from '@emotion/react'
import Layout from '../components/layout.js'
import Header from '../components/minLogoHeader.js'

const main = css`
  font-weight: 300;
  font-size: 14px;
  line-height: 1.5;
  width: 70vw;
  max-width: 1000px;
  margin: 0 auto 0 auto;
  padding: 14em 0 1em 0;

  @media screen and (max-width: 800px) {
    .fineprint { // expanded mobile header
      margin-top: 10em;
    }
  }

  hr {
    height: 0;
    margin: 1.5em 0 2.5em 0;
    margin: 0;
    padding: 0;
    opacity: 0;
  }
  h1 {
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.2;
    margin: 1.5em 0 1.5em 0;
    font-size: 1.5em;
  }
  h2 {
    font-size: inherit;
    font-weight: inherit;
    font-weight: 500;
    margin: 1.5em 0 1.5em 0;
    font-size: 1.2em;
  }
  h3 {
    font-size: inherit;
    font-weight: 500;
    margin: 0.5em 0 0.5em 0;
  }
`

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: {slug: {eq: $slug}}) {
      html
      frontmatter {
        description
        slug
        title
      }
    }
  }
`

export default function Issue({ data }) {
  const { html, frontmatter: { title, slug, description } } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <link rel="canonical" href={`https://money-times.de/${slug}`} />
        <meta name="description" content={description} />
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Header />
      <main css={main}>
        <div class="fineprint" dangerouslySetInnerHTML={{ __html: html }} />
      </main>
    </Layout>
  )
}
