export const FSM = {
  create: () => {
    let state = () => {}

    return {
      init: initial => {
        state = initial
        state('enter')
      },
      dispatch: event => state(event),
      tran: next => {
        state('exit')
        state = next
        state('enter')
      },
    }
  }
}
